import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBPrivacyPage from "../components/frontend/privacypage/privacy"

const PrivacyPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Shakespeare In Bits - Terms of Use" />
      <SIBPrivacyPage/>
    </Layout>
  )
}

export default PrivacyPage