import React from "react"

const SIBPrivacyPage = () => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Privacy Policy</h1>
              <p>We take your privacy very seriously.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content">
                <h2 className="heading-large-alt">
                  Committed to protecting your privacy
                </h2>
                <p>
                  The Shakespeare In Bits team is committed to protecting your
                  privacy and maintaining the security of any personal
                  information received from you.
                </p>
                <p>
                  1. We strictly adhere to the requirements of the data
                  protection legislation in Ireland and the European Union. The
                  purpose of this statement is to explain to you what personal
                  information we collect and how we may use it.
                </p>
                <p>
                  2. When you order, we need to know your name, address, and
                  personal details related to the transaction. This allows us to
                  process and fulfill your order. You have the option to
                  withhold personal information that is not required for the
                  order process.
                </p>
                <p>
                  3. We use your personal information:
                  <br />
                  to notify you of changes to the website
                  <br />
                  to update you about new products/services
                  <br />
                  to improve our products/services
                  <br />
                  to conduct research
                  <br />
                  You may choose not to receive communications and information
                  from www.shakespeareinbits.com at any time by sending an email with
                  your name and address to contact@shakespeareinbits.com.
                </p>
                <p>
                  4. We do not sell, rent or exchange your personal information
                  with any third party for commercial reasons, beyond the
                  essential requirement for credit/debit card validation during
                  purchase.
                </p>
                <p>
                  5. We follow strict security procedures in the storage and
                  disclosure of information which you have given us, to prevent
                  unauthorised access in accordance with EU data protection
                  legislation. We do not collect sensitive information about you
                  except when you specifically knowingly provide it. In order to
                  maintain the accuracy of our database, you can check, update
                  or remove your personal details by contacting us at
                  contact@shakespeareinbits.com. We use a technology called "cookies" as
                  part of a normal business procedure to track patterns of
                  behaviour of visitors to our site. A cookie is an element of
                  data that our Website sends to your browser which is then
                  stored on your system. You can set your browser to prevent
                  this happening. Any information collected in this way can be
                  used to identify you unless you change your browser settings.
                </p>
                <p>
                  6. In order to process credit/debit card transactions, the
                  bank or card processing agency may require to verify your
                  personal details for authorisation outside the EEA (European
                  Economic Area). Your information will not be transferred
                  outside the EEA for any other purpose.
                </p>
                <p>
                  7. if you have any questions about privacy please contact us
                  at: contact@shakespeareinbits.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBPrivacyPage
